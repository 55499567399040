const Admin_Panel = 'info@legacybookwriters.com';
const PHONE = '(855) 216-6440';
const PHONE_HREF = 'tel:8552166440';
const EMAIL = 'info@legacybookwriters.com';
const EMAIL_HREF = 'mailto:info@legacybookwriters.com';
const CONTACT_EMAIL = 'info@legacybookwriters.com';
const CONTACT_EMAIL_HREF = 'mailto:info@legacybookwriters.com';
const ADDRESS = '2029 Century Park E, Los Angeles, CA 90067';
const WEBSITE_NAME = 'Legacy Book Writers';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO =
  '';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
  WEBSITE_LOGO,
};
