import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import {
  Loader,
} from '../../assets/images'

export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "amazonbookwriter.com"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=710578';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://legacybookwriters.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  return (
    <Modal show={open} onHide={onCloseModal} className='main-popup' aria-labelledby="contained-modal-title-vcenter" centered>
      <div class="popupform" id="popupform">
        <a class="close-mark" href='javascript:;' onClick={onCloseModal}><i class="fa-solid fa-xmark"></i></a>
        <h2>Activate Coupon Now</h2>
        <p>HIRE <strong>TOP GHOSTWRITERS AT 85% OFF</strong> TO CREATE YOUR TIMELESS WORK</p>
        <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
          <ul>
            <li class="first"><i class="fa fa-user"></i> <input name="name" placeholder="Full Name *" required=""
              type="text" onChange={(e) => setFormData({ ...formData, name: e.target.value })} /></li>
            <li><i class="fa fa-envelope"></i> <input class="email" name="email" placeholder="Email Address *"
              required="" type="email" onChange={(e) => setFormData({ ...formData, email: e.target.value })} /></li>
            <li><i class="fa fa-phone"></i> <input class="number" name="phone" placeholder="Phone No. *" required=""
              type="number" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} /></li>
            <li><i class="fa fa-paper-plane"></i>
              <textarea name="message" placeholder="Enter a brief description about your book"
                required="" onChange={(e) => setFormData({ ...formData, message: e.target.value })}></textarea>
            </li>
            <li class="last">
              {loading == true ? (
                <div className="loader">
                  <img alt="loader" src={Loader} />
                </div>
              ) : (
                <button type="submit" value="Submit">Submit</button>
              )
              }
            </li>
          </ul>
        </form>
      </div>
    </Modal>
  );
};